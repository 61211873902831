/* input form css for username and password fields */

.login-form {
  width: 300px;
  margin: 0 auto;
  font-size: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-form input {
  width: 100%;
  margin-top: 10px;
  padding: 5px;
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}