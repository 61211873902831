.about-section {
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 20vh;
  /* the section should take the vertical space of the screen */
  min-height: 60vh;
}

h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
}


.about-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .about-section {
    padding: 30px 0;
  }

  .about-content {
    flex-direction: column;
    align-items: stretch;
  }
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card {
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.about-bt {
  padding: 15px;
  margin: 15px;
}